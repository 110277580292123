import { Box } from '@mui/material'
import { ReactNode } from 'react'
import Image from 'next/image'
import PrimeSnackBar from './components/primeSnackBar'

interface LayoutProps {
  children: ReactNode
}

export default function Layout({ children }: LayoutProps) {
  return (
    <>
      <Box
        sx={{
          mt: { xs: 1, sm: 3 },
          ml: { xs: 2, sm: 4 }
        }}
        display="inline-block"
      >
        <Image
          src="/images/prime-logo.svg"
          alt="Nerd Prime Logo"
          width={160}
          height={47}
        />
      </Box>
      <Box component="main">{children}</Box> 
      <PrimeSnackBar />
    </>
  )
}
