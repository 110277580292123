import { Alert, Snackbar } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from '../stores/appContext'

const PrimeSnackBar = () => {
  const { message, show, type, hideSnackBar } = useSnackbar()
  const vertical = 'bottom'
  const horizontal = 'right'
  return (<Snackbar
    open={show}
    autoHideDuration={6000}
    onClose={() => hideSnackBar()}
    anchorOrigin={{ vertical, horizontal }}
    sx={{ backdropFilter: 'blur(10px)' }}
  >
    <Alert variant="outlined" color={type}>
      {message}
    </Alert>
  </Snackbar>
  )
}

export default observer(PrimeSnackBar)