import { GetServerSideProps } from 'next'
import Layout from '../src/Layout'
import { Box } from '@mui/material'
import { useState } from 'react'
import { MultiFactorResolver } from 'firebase/auth'
import { RECAPTCHA_CONTAINER_ID } from '../src/utils/auth'
import dynamic from 'next/dynamic'
import { TwoFactorCodeProps } from '../src/components/auth/twoFactorCode/twoFactorCode'
import { LoginProps } from '../src/components/auth/login/login'

const Login = dynamic<LoginProps>(
  () => import('../src/components/auth/login/login'),
  { ssr: false }
)

const TwoFactorCode = dynamic<TwoFactorCodeProps>(
  () => import('../src/components/auth/twoFactorCode/twoFactorCode'),
  { ssr: false }
)

interface LoginPageProps {
  callback: string
}

export type LoginStepsType = 'login' | '2fa'
export type AuthTypeType = 'sms' | 'app'

const LoginPage: Prime.NextPageWithLayout = ({ callback }: LoginPageProps) => {
 
  const [ currentStep, setCurrentStep ] = useState<LoginStepsType>('login')
  const [ authType, setAuthType ] = useState<AuthTypeType>('sms')
  const [ mfaResolver, setMFAResolver ] = useState<MultiFactorResolver>()

  const getStep = ()=> {
    switch (currentStep){
      case '2fa':
        if(authType === 'sms' && !mfaResolver) {
          setCurrentStep('login')
          return
        }
        return (
          <TwoFactorCode
            callback={callback}
            setCurrentStep={setCurrentStep}
            mfaResolver={mfaResolver}
            authType={authType}
          />
        )
      case 'login': 
        return (
          <Login
            callback={callback}
            setCurrentStep={setCurrentStep}
            setMFAResolver={setMFAResolver}
            setAuthType={setAuthType}
          />
        )
      default:
        return (
          <Login
            callback={callback}
            setCurrentStep={setCurrentStep}
            setMFAResolver={setMFAResolver}
            setAuthType={setAuthType}
          />
        )
    }
  }

  return (
    <Box
      sx={{ 
        tableLayout: 'fixed',
        display: 'table',
        maxWidth: '480px',
        width: '100%',
        margin: 'auto'
      }}
    >
      <Box sx={{
        display: 'table-cell',
        height: 'calc(100vh - 102px)',
        verticalAlign: 'middle'
      }}>
        <>
          {getStep()}
          <div id={RECAPTCHA_CONTAINER_ID} />
        </>
      </Box>
    </Box>
  )
}


export const getServerSideProps: GetServerSideProps = async (context) => {
  const { query } = context
  return {
    props: {
      callback: query.callback ? query.callback : '/'
    }
  }
}

LoginPage.getLayout = function getLayout(page: React.ReactElement) {
  return (
    <Layout>
      {page}
    </Layout>
  )
}

export default LoginPage
